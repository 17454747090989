import { colorGold } from '@styles/color';
import { Link } from 'gatsby';
import tw, { styled } from 'twin.macro';

export const MainContent = styled.div`
  ${tw`max-w-7xl w-11/12 mx-auto`}
`;

export const H1 = styled.h1`
  ${tw`text-2xl text-gray-800 leading-loose font-semibold my-3 uppercase col-span-3`}
`;

export const H3 = styled.h3`
  ${tw`text-xl text-gray-800 leading-loose font-semibold my-2 mt-6`}
`;

export const Paragph = styled.p`
  ${tw`text-gray-800 leading-relaxed my-3`}
  text-indent: 2rem;
`;

export const StyledLink = styled(Link)`
  ${tw`text-gray-800 text-xs font-semibold antialiased tracking-wide m-3 p-3 transition-colors duration-300 ease-in-out cursor-pointer`}
  &:hover {
    color: ${colorGold};
  }
  color: rgb(35, 35, 35);
  letter-spacing: 1.1px;
  font-weight: 700;

  text-transform: uppercase;
`;

export const StyledWhiteLink = styled(Link)`
  ${tw`
  text-gray-50 text-xs
  font-semibold
  antialiased
  tracking-wide
  m-3 p-3
  transition-colors
  duration-300
  ease-in-out
  cursor-pointer`}
  &:hover {
    color: ${colorGold};
  }
  letter-spacing: 1.1px;
  font-weight: 700;
  text-transform: uppercase;
`;
